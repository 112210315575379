import React from "react";
import Image from "next/legacy/image";
import styled from "@emotion/styled";
import { Wrapper } from "../Wrapper";
import { getResizedImageURL, resizeWidthHeightFromWidth } from "../utils";
import { Link } from "../Link";
import { theme } from "../theme";

export enum FeaturedInColourWays {
  GREYBOY = "GREYBOY",
  SNOWDROP = "SNOWDROP",
}

const FeaturedInColourWayMapper = {
  [FeaturedInColourWays.GREYBOY]: {
    bg: theme.colors.mist,
    titleBg: theme.colors.snowdrop,
  },
  [FeaturedInColourWays.SNOWDROP]: {
    bg: theme.colors.snowdrop,
    titleBg: theme.colors.mist,
  },
};

const SectionWrapper = styled.section<{ colourWay: FeaturedInColourWays }>`
  background: ${(props) =>
    FeaturedInColourWayMapper[props.colourWay]?.bg || props.theme.colors.mist};
`;

const Title = styled.h3<{ colourWay: FeaturedInColourWays }>`
  background: ${(props) =>
    FeaturedInColourWayMapper[props.colourWay]?.titleBg ||
    props.theme.colors.snowdrop};
  border-bottom: 3px solid ${(props) => props.theme.colors.darkGrey};
  font-size: 14px;
  font-weight: normal;
  left: 20px;
  padding: 4px 12px;
  position: absolute;
  top: 0;

  @media ${(props) => props.theme.bp.desktopUp} {
    left: 0;
  }
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media ${(props) => props.theme.bp.tabletUp} {
    flex-wrap: nowrap;
  }
`;

const ListItem = styled.li`
  flex: 1 1 50%;
  mix-blend-mode: multiply;
  padding: 30px 30px;
  text-align: center;

  @media ${(props) => props.theme.bp.mobileUp} {
    padding: 30px 80px;
  }

  // Only show 6 on desktop
  &:nth-of-type(5),
  &:nth-of-type(6) {
    display: none;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    padding: 30px 10px;
    flex-basis: 25%;

    &:nth-of-type(5),
    &:nth-of-type(6) {
      display: unset;
    }
  }

  img {
    height: auto;
    width: 100%;
  }
`;

interface FeaturedInProps {
  title: string;
  mediaLogos: Array<{
    logo: { url: string; alt: string; width: number; height: number };
    link: string;
  }>;
  colourWay: FeaturedInColourWays;
}

export const FeaturedIn = ({
  title,
  mediaLogos,
  colourWay,
}: FeaturedInProps) => (
  <SectionWrapper colourWay={colourWay}>
    <Wrapper isExtraLarge style={{ position: "relative", padding: "40px 0" }}>
      <Title colourWay={colourWay}>{title}</Title>
      <List>
        {mediaLogos.map((item: any, idx: number) => {
          const imgURL = getResizedImageURL(item.logo.url, 350) || "";
          const { width, height } = resizeWidthHeightFromWidth(item.logo, 122);

          return (
            <ListItem key={idx}>
              <Link
                href={item.link}
                style={item.link ? {} : { pointerEvents: "none" }}
                target="_blank"
                title={item.logo.alt}
              >
                <Image
                  src={imgURL}
                  alt={item.logo.alt}
                  width={width}
                  height={height}
                />
              </Link>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  </SectionWrapper>
);
