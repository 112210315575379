import { FeaturedIn } from "@ecologi/react-components/src";

export default function FeaturedInWidget(props: any) {
  return (
    <FeaturedIn
      colourWay={props.data.colour_way}
      mediaLogos={props.data.media_logos}
      title={props.data.title || "We work with"}
    />
  );
}
